import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _extends from "@babel/runtime/helpers/extends";
import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useAlert } from "react-alert";
import { commonMessages } from "@temp/intl";
import { accountConfirmUrl } from "../../../app/routes";
import { Button, Form, TextField } from "../..";
import { maybe } from "../../../core/utils";
import { TypedAccountRegisterMutation } from "./queries";

var showSuccessNotification = function showSuccessNotification(data, hide, alert, intl) {
  var successful = maybe(function () {
    return !data.accountRegister.errors.length;
  });

  if (successful) {
    hide();
    alert.show({
      title: data.accountRegister.requiresConfirmation ? intl.formatMessage({
        defaultMessage: "Please check your e-mail for further instructions",
        "id": "components.OverlayManager.Login.RegisterForm.2622394113"
      }) : intl.formatMessage({
        defaultMessage: "New user has been created",
        "id": "components.OverlayManager.Login.RegisterForm.590842025"
      })
    }, {
      type: "success",
      timeout: 5000
    });
  }
};

var RegisterForm = function RegisterForm(_ref) {
  var hide = _ref.hide;
  var alert = useAlert();
  var intl = useIntl();
  return React.createElement(TypedAccountRegisterMutation, {
    onCompleted: function onCompleted(data) {
      return showSuccessNotification(data, hide, alert, intl);
    }
  }, function (registerCustomer, _ref2) {
    var loading = _ref2.loading,
        data = _ref2.data;
    return React.createElement(Form, {
      errors: maybe(function () {
        return data.accountRegister.errors;
      }, []),
      onSubmit: function onSubmit(event, _ref3) {
        var email = _ref3.email,
            password = _ref3.password;
        event.preventDefault();
        var redirectUrl = "".concat(window.location.origin).concat(accountConfirmUrl);
        registerCustomer({
          variables: {
            email: email,
            password: password,
            redirectUrl: redirectUrl
          }
        });
      }
    }, React.createElement(TextField, {
      placeholder: "First Name",
      name: "firstName",
      autoComplete: "firstName",
      type: "text",
      required: true
    }), React.createElement(TextField, {
      placeholder: "Last Name",
      name: "lastName",
      autoComplete: "lastName",
      type: "text",
      required: true
    }), React.createElement(TextField, {
      placeholder: "Email",
      name: "email",
      autoComplete: "email",
      type: "email",
      required: true
    }), React.createElement(TextField, {
      placeholder: "Confirm Email",
      name: "confirmEmail",
      autoComplete: "confirmEmail",
      type: "email",
      required: true
    }), React.createElement("div", null, React.createElement(TextField, {
      placeholder: "Password",
      name: "password",
      autoComplete: "password",
      type: "password",
      required: true
    }), React.createElement(FormattedMessage, {
      id: "components.OverlayManager.Login.RegisterForm.117355931",
      defaultMessage: "Password must containt an uppercase letter, a numeric character,\r\nand a special character"
    })), React.createElement(TextField, {
      placeholder: "Confirm Password",
      name: "password",
      autoComplete: "password",
      type: "password",
      required: true
    }), React.createElement(TextField, {
      placeholder: "Phone Number",
      name: "phone",
      autoComplete: "phone",
      type: "phone",
      required: true
    }), React.createElement(TextField, {
      placeholder: "Date of Birth (Select Date)",
      name: "birthday",
      autoComplete: "birthday",
      type: "birthday",
      required: true
    }), React.createElement("div", {
      className: "rewards-info"
    }, "By creating an account, you\u2019ll automatically join our rewards program (l\u2019achiever Rewards)."), React.createElement("div", {
      className: "recaptcha"
    }, "reCAPTCHA (to be added by IT team)"), React.createElement("div", {
      className: "checkbox-row"
    }, React.createElement("div", null, React.createElement("input", {
      type: "checkbox"
    }), React.createElement("span", null, "Yes, I agree with l\u2019achiever\u2019s Privacy Policy and Terms of Use.")), React.createElement("div", null, React.createElement("input", {
      type: "checkbox"
    }), React.createElement("span", null, "Yes, I would like to receive email updates with exclusive offers."))), React.createElement("div", {
      className: "login__content__button"
    }, React.createElement(Button, _extends({
      testingContext: "submitRegisterFormButton",
      type: "submit"
    }, loading && {
      disabled: true
    }), loading ? intl.formatMessage(commonMessages.loading) : intl.formatMessage(_defineProperty({
      defaultMessage: "Create An Account",
      "id": "components.OverlayManager.Login.RegisterForm.3112162510"
    }, "id", "components.OverlayManager.Login.RegisterForm.3112162510")))));
  });
};

export default RegisterForm;