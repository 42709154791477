import React from "react";
import { Container } from "@components/templates";
import videoImage from "images/home-2.png";
import * as S from "./styles";
export var HomeVideo = function HomeVideo() {
  return React.createElement(S.OuterWrapper, null, React.createElement(Container, null, React.createElement(S.Wrapper, null, React.createElement("div", {
    className: "video-container"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("video", {
    poster: videoImage,
    src: "https://cdn.tfchina.org/la/video/a1.mp4",
    controls: true
  }, "\u60A8\u7684\u6D4F\u89C8\u5668\u4E0D\u652F\u6301 video \u6807\u7B7E\u3002", React.createElement("track", {
    default: true,
    kind: "captions",
    srcLang: "en",
    src: ""
  }))), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", {
    className: "video-title"
  }, "Ingredient Video Title"), React.createElement("p", null, "text..."))))), React.createElement(S.ImageWrapper, null, React.createElement("div", {
    className: "static-img"
  }, React.createElement("p", {
    className: "img-info"
  }, "Minimalist skin care is to choose the scientifically based part after \"breaking away\" among the many choices, clear the skin care appeal and prescribe the active ingredients to the skin problem."))));
};
HomeVideo.displayName = "HomeVideo";
export default HomeVideo;