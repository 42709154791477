import React from "react";
import { Container } from "@components/templates";
import { Button } from "src/components";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
export var Readmore = function Readmore() {
  return React.createElement(S.OuterWrapper, null, React.createElement(Container, null, React.createElement(S.Wrapper, null, React.createElement("div", {
    className: "readmore-container"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("h2", null, "REDEFINE MEN SKINIMALISM"), React.createElement("p", null, "Minimalist the number of product used, and minimalist the product ingredients.l'achiever committed to the of \"harmless\" product concepts, including safety, safety, sustainability and affordability. (Button link to this part under brand story)")), React.createElement("div", {
    className: "right-section"
  }, React.createElement(Button, {
    testingContext: "readMore"
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.Readmore.Readmore.1341826899",
    defaultMessage: "Read More"
  })))))), React.createElement(S.ImageWrapper, null, React.createElement("div", {
    className: "static-img"
  })));
};
Readmore.displayName = "Readmore";
export default Readmore;