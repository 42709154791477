import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject12() {
  var data = _taggedTemplateLiteral(["\n  font-size: 16px;\n  font-weight: 700;\n  color: #426d61;\n  line-height: 3rem;\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = _taggedTemplateLiteral(["\n  box-sizing: border-box;\n  width: calc(100% - 2.26rem);\n  float: right;\n  padding-bottom: 3rem;\n  textarea {\n    padding: 20px;\n    width: 100%;\n    height: 100px;\n    border: 1px solid ", ";\n    transition: all 0.3s ease;\n    &:focus-visible {\n      outline: none;\n    }\n    &:hover {\n      outline-width: 1px;\n      outline-style: solid;\n      border-color: #426d61;\n      outline-color: ", ";\n    }\n  }\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  line-height: 3rem;\n  display: flex;\n  &:first-child {\n    margin-top: 1rem;\n  }\n  .payment_name {\n    width: 10rem;\n  }\n  .svg {\n    margin-left: 12px;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 1rem 0 1.6rem 0;\n  border-bottom: 2px solid\n    ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  padding: 2rem 0 0 0;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 350px;\n  height: 50px;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: 10px 0;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  width: 350px;\n  margin: 10px 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  width: 100%;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  .title {\n    line-height: 40px;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Title = styled.h3(_templateObject2(), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var AddressSelect = styled.div(_templateObject3());
export var CreateNewAddress = styled.div(_templateObject4());
export var ShortInput = styled.div(_templateObject5());
export var LongInput = styled.div(_templateObject6());
export var ContrySelect = styled.div(_templateObject7());
export var DeliverWrapper = styled.div(_templateObject8());
export var BorderLineTitle = styled.h3(_templateObject9(), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var RadioWrapper = styled.div(_templateObject10());
export var DeliveryTextArea = styled.div(_templateObject11(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var PaymentFooterTitle = styled.h3(_templateObject12());