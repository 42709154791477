import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n  grid-area: button;\n  margin: 3rem 0 45px 0;\n  span {\n    font-weight: 200;\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n    position: fixed;\n    bottom: 0;\n  "]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  grid-area: checkoutSummary;\n  height: 100%;\n\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  grid-area: paymentGateways;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  grid-area: checkout;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  grid-area: userInfo;\n  margin-top: 56px;\n  width: 100%;\n  .welcome {\n    color: #93b4ab;\n    font-size: 24px;\n    font-weight: bold;\n  }\n  p.loginType {\n    line-height: 4rem;\n    span {\n      font-weight: bold;\n    }\n    border-bottom: 2px solid\n      ", ";\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  grid-area: navigation;\n  width: 100%;\n  margin-top: 60px;\n  h1 {\n    font-size: 3rem;\n    font-weight: 700;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-template-areas:\n      \"navigation\"\n      \"userInfo\"\n      \"checkout\"\n      \"paymentGateways\"\n      \"button\";\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n\n  grid-template-columns: 8fr 4fr;\n  grid-template-rows: 85px auto auto;\n  grid-column-gap: 30px;\n  grid-template-areas:\n    \"navigation checkoutSummary\"\n    \"userInfo checkoutSummary\"\n    \"checkout checkoutSummary\"\n    \"paymentGateways checkoutSummary\"\n    \"button checkoutSummary\";\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(255, 255, 255, 0.7);\n  z-index: 10;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Loader = styled.div(_templateObject());
export var Wrapper = styled.div(_templateObject2(), media.mediumScreen(_templateObject3()));
export var SecureTitle = styled.div(_templateObject4());
export var UserInfo = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
}); // export const Navigation = styled.div`
//   grid-area: navigation;
//   border-bottom: 1px solid
//     ${props => props.theme.colors.baseFontColorTransparent};
//   padding-bottom: 43px;
//   height: 85px;
// `;

export var Checkout = styled.div(_templateObject6());
export var PaymentGateways = styled.div(_templateObject7(), function (props) {
  return props.hide && "display: none;";
});
export var CheckoutSummary = styled.div(_templateObject8(), media.mediumScreen(_templateObject9()));
export var Button = styled.div(_templateObject10());