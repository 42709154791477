import _objectDestructuringEmpty from "@babel/runtime/helpers/objectDestructuringEmpty";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCart, useCheckout } from "@saleor/sdk";
import { ThankYou, CartSummary } from "@components/organisms";
import { BASE_URL } from "@temp/core/config";
import { generateGuestOrderDetailsUrl } from "@utils/core";

var prepareCartSummary = function prepareCartSummary(totalPrice, subtotalPrice, shippingTaxedPrice, promoTaxedPrice, items) {
  var products = items === null || items === void 0 ? void 0 : items.map(function (_ref) {
    var _variant$product, _variant$product$thum, _variant$product2, _variant$product2$thu, _variant$product3, _variant$product3$thu;

    var id = _ref.id,
        variant = _ref.variant,
        totalPrice = _ref.totalPrice,
        quantity = _ref.quantity;
    return {
      id: id || "",
      name: variant.name || "",
      price: {
        gross: {
          amount: (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.amount) || 0,
          currency: (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.gross.currency) || ""
        },
        net: {
          amount: (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.net.amount) || 0,
          currency: (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.net.currency) || ""
        }
      },
      quantity: quantity,
      sku: variant.sku || "",
      thumbnail: {
        alt: ((_variant$product = variant.product) === null || _variant$product === void 0 ? void 0 : (_variant$product$thum = _variant$product.thumbnail) === null || _variant$product$thum === void 0 ? void 0 : _variant$product$thum.alt) || undefined,
        url: (_variant$product2 = variant.product) === null || _variant$product2 === void 0 ? void 0 : (_variant$product2$thu = _variant$product2.thumbnail) === null || _variant$product2$thu === void 0 ? void 0 : _variant$product2$thu.url,
        url2x: (_variant$product3 = variant.product) === null || _variant$product3 === void 0 ? void 0 : (_variant$product3$thu = _variant$product3.thumbnail2x) === null || _variant$product3$thu === void 0 ? void 0 : _variant$product3$thu.url
      }
    };
  });
  return React.createElement(CartSummary, {
    shipping: shippingTaxedPrice,
    subtotal: subtotalPrice,
    promoCode: promoTaxedPrice,
    total: totalPrice,
    products: products,
    showType: "thanyouPage"
  });
};

var ThankYouPage = function ThankYouPage(_ref2) {
  var _checkout$shippingMet;

  _objectDestructuringEmpty(_ref2);

  var _useCart = useCart(),
      shippingPrice = _useCart.shippingPrice,
      subtotalPrice = _useCart.subtotalPrice,
      discount = _useCart.discount,
      totalPrice = _useCart.totalPrice,
      items = _useCart.items;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout;

  var shippingTaxedPrice = (checkout === null || checkout === void 0 ? void 0 : (_checkout$shippingMet = checkout.shippingMethod) === null || _checkout$shippingMet === void 0 ? void 0 : _checkout$shippingMet.id) && shippingPrice ? {
    gross: shippingPrice,
    net: shippingPrice
  } : null;
  var promoTaxedPrice = discount && {
    gross: discount,
    net: discount
  };
  var location = useLocation();
  var history = useHistory();
  var _location$state = location.state,
      token = _location$state.token,
      orderNumber = _location$state.orderNumber;
  return React.createElement(ThankYou, {
    continueShopping: function continueShopping() {
      return history.push(BASE_URL);
    },
    checkoutSummary: prepareCartSummary(totalPrice, subtotalPrice, shippingTaxedPrice, promoTaxedPrice, items),
    orderNumber: orderNumber,
    orderDetails: function orderDetails() {
      return history.push(generateGuestOrderDetailsUrl(token));
    }
  });
};

export { ThankYouPage };