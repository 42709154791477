import React from "react";
import { FormattedMessage } from "react-intl"; // import { Link } from "react-router-dom";

import { Button, OverlayTheme, OverlayType } from "..";

var CheckoutAsGuest = function CheckoutAsGuest(_ref) {
  var overlay = _ref.overlay,
      checkoutUrl = _ref.checkoutUrl;
  return React.createElement("div", {
    className: "checkout-login__guest"
  }, React.createElement("h3", {
    className: "checkout__header"
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.1001058465",
    defaultMessage: "New to l'achiever?"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.632192191",
    defaultMessage: "Sign up and start earning rewards today! Enjoy\r\nmemner benefits and faster checkout."
  })), React.createElement(Button, {
    testingContext: "createAnAccountButton",
    onClick: function onClick() {
      return overlay.show(OverlayType.register, OverlayTheme.right);
    }
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.2132450234",
    defaultMessage: "create an account"
  })));
};

export default CheckoutAsGuest;