import "./scss/index.scss";
import React from "react";
import { Redirect } from "react-router";
import { useAuth } from "@saleor/sdk";
import { FormattedMessage } from "react-intl";
import { Offline, OfflinePlaceholder, Online } from "..";
import RegisterForm from "../OverlayManager/Login/RegisterForm"; // import RegisterForm from "../OverlayManager/Login/RegisterForm";
// import { OverlayType, OverlayTheme } from "../Overlay";

var NewAccount = function NewAccount() {
  // const overlay = useContext(OverlayContext);
  var _useAuth = useAuth(),
      user = _useAuth.user; // const { show } = overlay;
  // const showPasswordResetOverlay = () => {
  //   show(OverlayType.password, OverlayTheme.right);
  // };


  if (user) {
    return React.createElement(Redirect, {
      to: "/checkout/"
    });
  }

  return React.createElement("div", {
    className: "container"
  }, React.createElement(Online, null, React.createElement("div", {
    className: "new-account-form"
  }, React.createElement("h2", null, React.createElement(FormattedMessage, {
    id: "components.NewAccount.index.3112162510",
    defaultMessage: "Create An Account"
  })), React.createElement(RegisterForm, {
    hide: function hide() {}
  }))), React.createElement(Offline, null, React.createElement(OfflinePlaceholder, null)));
};

export default NewAccount;