import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .each-slide > div {\n    display: flex !important;\n    align-items: flex-start !important;\n    justify-content: center !important;\n    background-size: cover !important;\n    height: 200px !important;\n    flex-direction: column;\n  }\n\n  .each-slide span {\n    padding: 20px !important;\n    font-size: 20px !important;\n    background: #efefef !important;\n    text-align: center !important;\n  }\n  button[aria-label=\"Previous Slide\"] {\n    display: none;\n  }\n  .indicators {\n    justify-content: flex-start !important;\n    margin-left: 50px;\n  }\n  .react-slideshow-container + ul.indicators .each-slideshow-indicator:before {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    content: \"\";\n    background: #bbb;\n    text-align: center;\n  }\n  .react-slideshow-container\n    + ul.indicators\n    .each-slideshow-indicator.active:before {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 15px;\n    height: 15px;\n    border-radius: 50%;\n    content: \"\";\n    background: #426d60;\n    text-align: center;\n  }\n  .react-slideshow-container + ul.indicators li {\n    display: inline-block;\n    position: relative;\n    width: 15px;\n    height: 15px;\n    padding: 10px;\n    margin: 0;\n  }\n  .react-slideshow-wrapper.slide {\n    margin-left: 50px;\n  }\n  .text-info {\n    margin-right: 80px;\n    font-size: 20px;\n    font-weight: 500;\n  }\n  .user {\n    font-size: 25px;\n    font-weight: 500;\n    margin-top: 40px;\n  }\n  svg {\n    width: 80px;\n    height: 80px;\n  }\n  svg path {\n    fill: #a8c3bb;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());