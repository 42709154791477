import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  text-align: center;\n  margin: 2.5rem 0;\n  button {\n    color: #426d61;\n    width: auto;\n    box-shadow: inset 0px 0px 0px 3px #426d61;\n    border-left: 1px solid #426d61;\n    border-right: 1px solid #426d61;\n    &:hover {\n      background-color: #426d61;\n      color: #fff;\n    }\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-gap: 1rem;\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 1.5rem;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  grid-gap: 2rem;\n\n  ", "\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var List = styled.div(_templateObject(), media.largeScreen(_templateObject2()), media.smallScreen(_templateObject3()));
export var Loader = styled.div(_templateObject4());