import React from "react"; // import { FormattedMessage } from "react-intl";

import { Container } from "@components/templates";
import * as S from "./styles";
export var DiscoverUs = function DiscoverUs() {
  return React.createElement(S.Wrapper, null, React.createElement("div", null, React.createElement("div", {
    className: "home-page__hero"
  }, React.createElement("div", {
    className: "home-page__hero-container"
  }, React.createElement("div", {
    className: "home-page__hero-text"
  }))), React.createElement("div", {
    className: "home-page__hero secondary"
  }), React.createElement("div", {
    className: "home-page__hero third"
  }), React.createElement("div", {
    className: "home-page__hero fourth"
  }), React.createElement("div", {
    className: "fifth"
  }), React.createElement(Container, null, React.createElement("div", {
    className: "sixth"
  }, React.createElement("div", {
    className: "left-section"
  }), React.createElement("div", {
    className: "right-section"
  }, React.createElement("p", null, "Regression ingredients are relatively simple and effective, but the formula that can really improve the skin is the core content of minimalist skin care. First of all, the number of ingredients is not completely related to minimalist skin care. Product formulations need to think about how to retain pure and simple raw materials that are working to the skin, and get rid of the ingredients and some conceptual additives that will have negative effects in the formulation. At the same time, it does not cause too much burden. Committed to the development of harmless product concepts, including safety, sustainability and affordability. Combining the minimum safety and the natural ingredients of carefully researched synthetic materials, it has multiple functions while retaining the minimalist product ingredients.")))), React.createElement("div", {
    className: "home-page__hero seventh"
  }), React.createElement("div", {
    className: "eighth"
  }, React.createElement("div", {
    className: "top-section"
  }), React.createElement("div", {
    className: "bottom-section"
  }, "(More info?)"))));
};
DiscoverUs.displayName = "DiscoverUs";
export default DiscoverUs;