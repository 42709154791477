import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .home-page__hero {\n    background-image: url(", ");\n    background-repeat: no-repeat;\n    min-height: 550px;\n  }\n  .secondary {\n    background-image: url(", ");\n  }\n  .third {\n    background-image: url(", ");\n    min-height: 200px;\n  }\n  .fourth {\n    background-image: url(", ");\n    min-height: 120px;\n    // background-size: contain;\n  }\n  .fifth {\n    background-image: url(", ");\n    min-height: 150px;\n    background-size: contain;\n    background-repeat: no-repeat;\n  }\n  .sixth {\n    margin: 80px 0;\n    display: grid;\n    grid-template-columns: 50% 50%;\n    min-height: 400px;\n    .left-section {\n      background-image: url(", ");\n      min-height: 150px;\n      background-size: cover;\n      background-repeat: no-repeat;\n    }\n    .right-section {\n      align-self: center;\n      padding-left: 50px;\n      text-align: justify;\n      font-weight: 500;\n    }\n  }\n\n  .seventh {\n    background-image: url(", ");\n  }\n\n  .eighth {\n    min-height: 300px;\n    margin: 80px 80px;\n    .top-section {\n      width: 100%;\n      background-image: url(", ");\n      min-height: 250px;\n      background-size: contain;\n      background-repeat: no-repeat;\n    }\n    .bottom-section {\n      font-size: 30px;\n      font-weight: 900;\n      margin-top: 80px;\n      text-align: center;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import discoverusBanner from "images/discoverus-banner.png";
import discoverus1 from "images/discoverus-1.png";
import discoverus2 from "images/discoverus-2.png";
import discoverus3 from "images/discover-3.png";
import discoverus4 from "images/discover-4.png";
import discoverus5 from "images/discover-5.png";
import discoverus6 from "images/discover-6.png";
import discoverus7 from "images/discover-7.png";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), discoverusBanner, discoverus1, discoverus2, discoverus3, discoverus4, discoverus5, discoverus6, discoverus7);