import React from "react";
import { FormattedMessage } from "react-intl";
import { Container } from "@components/templates";
import ingredients1 from "images/ingredients.png";
import ingredients2 from "images/ingredients-2.png";
import ingredients3 from "images/ingredients-3.png";
import ingredients4 from "images/ingredients-4.png";
import ingredients5 from "images/ingredients-5.png";
import ingredients6 from "images/ingredients-6.png";
import ingredients7 from "images/ingredients-7.png";
import ingredients8 from "images/ingredients-8.png";
import ingredients9 from "images/ingredients-9.png";
import * as S from "./styles";
export var Ingredients = function Ingredients() {
  return React.createElement(S.Wrapper, null, React.createElement("div", null, React.createElement("div", {
    className: "home-page__hero"
  }, React.createElement("div", {
    className: "home-page__hero-container"
  }, React.createElement("div", {
    className: "home-page__hero-text"
  }, React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title custom-title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.Ingredients.Ingredients.794568209",
    defaultMessage: "OUR INGREDIENTS"
  })))), React.createElement("div", null, React.createElement("span", {
    className: "home-page__hero__title sub-custom-title"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.Ingredients.Ingredients.3503574862",
    defaultMessage: "Combining the power of nature and science, l\u2019achiever men skincare products selected\r\nNew Zealand's purely natural ingredients, corporate with biotechnology extraction\r\ntechnology with a local third-party laboratory and manufacturer in New Zealand."
  })))))))), React.createElement(Container, null, React.createElement("div", {
    className: "content-container"
  }, React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients1
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", null, "NZ MANUKA HONEY"), React.createElement("p", null, "Manukau Honey is a natural humectant. It is extremely rich in proteins, vitamins and minerals and has the outstanding ability to nourish soothe and heal sensitive skin. Due to Manuka honey s anti-inflammatory properties, it can help to heal inflamed acne and can help to relieve redness and other skin conditions such as eczema in a completely natural way."), React.createElement("div", {
    className: "border"
  }))), React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("h2", null, "NZ SAUVIGNON BLANC GRAPE SEEDS EXTRACT"), React.createElement("p", null, "Advanced protective for sun and after-sun care. Prevent environmental damage with harmful rays and blue screen light, and important in defending against free radicals. Super powerful antioxidant Complex and anti-aging protector delivers skin nourishing nutrients to help maintain a healthy brightened appearance.", " "), React.createElement("div", {
    className: "border"
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients2
  })))), React.createElement("div", {
    className: "content-container"
  }, React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients3
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", null, "NZ HERBAL POHUTUKAWA EXTRACT"), React.createElement("p", null, "Pohutakawa is a native New Zealand tree, and it was as used in traditional Maori herbal medicine for wounds and abrasions. The bark contains Ellagic Acid which has antioxidant and anti-inflammatory properties. Pohutukawa extract helps calm and soothe red, irritated skin whilst deeply moisturizing it."), React.createElement("div", {
    className: "border"
  }))), React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("h2", null, "NZ CALENDULA WATER"), React.createElement("p", null, "Text..."), React.createElement("div", {
    className: "border"
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients4
  })))), React.createElement("div", {
    className: "content-container"
  }, React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients5
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", null, "NZ CERTIFICATED ORGANIC OLIVE OIL"), React.createElement("p", null, "Text..."), React.createElement("div", {
    className: "border"
  }))), React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("h2", null, "FOOD GRADE SOYBEAN LECITHIN"), React.createElement("p", null, "Text..."), React.createElement("div", {
    className: "border"
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients6
  })))), React.createElement("div", {
    className: "content-container"
  }, React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients7
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", null, "ALPINE WILLOW HERB EXTRACT"), React.createElement("p", null, "Text..."), React.createElement("div", {
    className: "border"
  }))), React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("h2", null, "NZ BIOACTIVE TOTAROL"), React.createElement("p", null, "Text..."), React.createElement("div", {
    className: "border"
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients8
  })))), React.createElement("div", {
    className: "content-container"
  }, React.createElement("div", {
    className: "content-section"
  }, React.createElement("div", {
    className: "left-section"
  }, React.createElement("img", {
    alt: "",
    src: ingredients9
  })), React.createElement("div", {
    className: "right-section"
  }, React.createElement("h2", null, "MARINE COLLAGEN NANOTECHNOLOGY"), React.createElement("p", null, "Text..."), React.createElement("div", {
    className: "border"
  }))))));
};
Ingredients.displayName = "Ingredients";
export default Ingredients;