import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import { checkoutMessages } from "@temp/intl";
import checkpng from "src/images/Check-sircle.png";
import * as S from "./styles";

/**
 * Thank you page after completing the checkout.
 */
var HFTitleWrapper = function HFTitleWrapper(props) {
  return React.createElement(S.HFTitleWrapper, null, React.createElement("h4", null, props.title), React.createElement(S.Paragraph, null, props.texts && props.texts.map(function (i) {
    return React.createElement("p", {
      key: i
    }, i);
  })));
};

var ThankYou = function ThankYou(_ref) {
  var orderNumber = _ref.orderNumber,
      continueShopping = _ref.continueShopping,
      orderDetails = _ref.orderDetails,
      checkoutSummary = _ref.checkoutSummary;
  return React.createElement(Container, {
    "data-test": "thankYouView"
  }, React.createElement(S.Wrapper, null, React.createElement(S.ThankYouHeader, null, React.createElement("img", {
    alt: "",
    src: checkpng
  }), React.createElement("div", null, React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.3746180736",
    defaultMessage: "Thank you "
  }), "(userName)!"), React.createElement("span", null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.4156660393",
    defaultMessage: "Your order "
  }), orderNumber, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.2812422303",
    defaultMessage: " is confirmed."
  })))), React.createElement(HFTitleWrapper, {
    title: "Order Status",
    texts: ["You will receive shipping and delivery updates by email. ", "You can check your order under My Account."]
  }), React.createElement(HFTitleWrapper, {
    title: "Your Contact Information",
    texts: ["(#email)"]
  }), React.createElement(HFTitleWrapper, {
    title: "Billing Information",
    texts: ["(First name,Last name", "Contact number:", "Address", "Suburb", "City/Region", "Postal Code", "Country)"]
  }), React.createElement(S.Buttons, null, React.createElement(Button, {
    testingContext: "continueShoppingButton",
    onClick: continueShopping
  }, React.createElement(FormattedMessage, checkoutMessages.continueShopping)), React.createElement(Button, {
    testingContext: "gotoOrderDetailsButton",
    onClick: orderDetails,
    fullWidth: true
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.3752098833",
    defaultMessage: "ORDER DETAILS"
  }))), React.createElement(S.CheckoutSummary, null, checkoutSummary)));
};

export { ThankYou };