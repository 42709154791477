import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n      font-size: ", ";\n    "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  html {\n    box-sizing: border-box;\n  }\n\n  *, *:before, *:after {\n    box-sizing: inherit;\n  }\n\n  body {\n    margin: 0 auto;\n    min-width: 320px;\n    font-family: ", ";\n    font-size: ", ";\n    line-height: ", ";\n    color: ", ";\n  }\n\n  input, textarea, button {\n    font-family: inherit;\n  }\n\n  h1 {\n    font-size: ", ";\n    line-height: ", ";\n\n    ", "\n  }\n\n  h3 {\n    font-size: ", ";\n    line-height: 1.7rem;\n  }\n\n  h4 {\n    font-size: ", ";\n  }\n\n  a {\n    text-decoration: none;\n    font-weight: normal;\n    color: inherit;\n  }\n\n  p {\n    line-height: 1.5rem;\n  }\n\n  button {\n    border: none;\n    background-color: transparent;\n    cursor: pointer;\n    outline: none;\n    padding: 0;\n  }\n\n  ul {\n    list-style: none;\n  }\n\n  #root {\n    display: flex;\n    min-height: 100vh;\n    flex-direction: column;\n\n    & > div:first-of-type {\n      flex: 1;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { createGlobalStyle } from "styled-components";
import { media } from ".";
export var GlobalStyle = createGlobalStyle(_templateObject(), function (props) {
  return props.theme.typography.baseFontFamily;
}, function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return props.theme.typography.baseLineHeight;
}, function (props) {
  return props.theme.colors.baseFont;
}, function (props) {
  return props.theme.typography.h1FontSize;
}, function (props) {
  return props.theme.typography.h1LineHeight;
}, function (props) {
  return media.smallScreen(_templateObject2(), props.theme.typography.h2FontSize);
}, function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.typography.h4FontSize;
});