import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  .static-img {\n    width: 100%;\n    min-height: 400px;\n    overflow: hidden;\n    background-image: url(", ");\n    background-size: cover;\n    background-repeat: no-repeat;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin-bottom: 60px;\n  margin-top: 60px;\n  .readmore-container {\n    display: grid;\n    grid-template-columns: 65% 35%;\n  }\n  .left-section,\n  .right-section {\n    h2 {\n      font-size: 2rem;\n      font-weight: 900;\n    }\n    p {\n      margin-top: 20px;\n      font-size: 16px;\n      font-weight: 500;\n      text-align: justify;\n    }\n  }\n  .right-section {\n    padding-left: 80px;\n    align-self: center;\n    button.button {\n      transform: none;\n      background-color: #fff !important;\n      width: 100%;\n      border: solid 1px #426d61;\n      color: #426d61;\n      box-shadow: none;\n      span {\n        transform: none;\n        color: #426d61;\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import readmore from "images/readmore.png";
import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var ImageWrapper = styled.div(_templateObject2(), readmore);
export var OuterWrapper = styled.div(_templateObject3());