import React from "react"; // import ReactSelect from "react-select";

import { Loader } from "@components/atoms";
import { FormattedMessage } from "react-intl";
import { checkoutMessages } from "@temp/intl";
import { Container } from "../Container";
import * as S from "./styles";

/**
 * Template for checkout page.
 */
var Checkout = function Checkout(_ref) {
  var user = _ref.user,
      loading = _ref.loading,
      navigation = _ref.navigation,
      checkout = _ref.checkout,
      paymentGateways = _ref.paymentGateways,
      _ref$hidePaymentGatew = _ref.hidePaymentGateways,
      hidePaymentGateways = _ref$hidePaymentGatew === void 0 ? false : _ref$hidePaymentGatew,
      checkoutSummary = _ref.checkoutSummary,
      button = _ref.button;
  var userCopy = user;
  return React.createElement(Container, null, loading && React.createElement(S.Loader, null, React.createElement(Loader, {
    fullScreen: true
  })), React.createElement(S.Wrapper, null, React.createElement(S.SecureTitle, null, React.createElement("h1", null, React.createElement(FormattedMessage, checkoutMessages.secureCheckout))), React.createElement(S.UserInfo, null, React.createElement("p", {
    className: "welcome"
  }, React.createElement(FormattedMessage, checkoutMessages.hey), "\xA0", userCopy.firstName + userCopy.lastName, ","), React.createElement("p", {
    className: "loginType"
  }, "You are signed in as", React.createElement("span", null, " ", userCopy.email))), React.createElement(S.Checkout, null, checkout), React.createElement(S.PaymentGateways, {
    hide: hidePaymentGateways
  }, paymentGateways), React.createElement(S.CheckoutSummary, null, checkoutSummary), React.createElement(S.Button, null, button)));
};

export { Checkout };